import actions from './actions'

const initialState = {
  loading: false,
  posts: [],
  post: {},
  retrieveLoading: false,
  createLoading: false,
  deleteLoading: false,
  updateLoading: false,
  ads: [],
  totalCount: 0,
  adsCount: 0
}

export default function postReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
