const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  USER_REGISTER: 'USER_REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FETCH_USERS: 'FETCH_USERS',
  FETCH_KYC: 'FETCH_KYC',
  RETRIEVE_USER: 'RETRIEVE_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  FETCH_ANALYTICS: 'FETCH_ANALYTICS',
  BLOCK_USER: 'BLOCK_USER',
  UNBLOCK_USER: 'UNBLOCK_USER',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  SET_KYC_STATUS: 'SET_KYC_STATUS',
  DELETE_KYC: 'DELETE_KYC',
  FETCH_TOTAL_DATA: 'FETCH_TOTAL_DATA',
  TFA_LOGIN: 'TFA_LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD'
}

export default actions
