const actions = {
  SET_STATE: 'comments/SET_STATE',
  FETCH_COMMENTS: 'FETCH_COMMENTS',
  RETRIEVE_COMMENT: 'RETRIEVE_COMMENT',
  CREATE_COMMENT: 'CREATE_COMMENT',
  UPDATE_COMMENT: 'UPDATE_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
}

export default actions
