import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'

import * as CitiesApi from '../../services/api/cities.api';

import actions from './actions'

export function* fetchCities({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(CitiesApi.fetchCities, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cities: response.data,
        totalCount: response.totalCount,
        loading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* createCity({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      createLoading: true,
    },
  })

  const response = yield call(CitiesApi.createCity, payload)

  if (response) {
    yield put({
      type: actions.FETCH_CITIES,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/cities')
    notification.success({
      message: 'Create Success',
      description: 'You have successfully create a city!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        createLoading: false,
      },
    })
  }
}

export function* retrieveCity({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      retrieveLoading: true,
    },
  })

  const response = yield call(CitiesApi.retrieveCity, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        city: response,
        retrieveLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        retrieveLoading: false,
      },
    })
  }
}

export function* updateCity({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updateLoading: true,
    },
  })

  const { id, body } = payload;

  const response = yield call(CitiesApi.updateCity, id, body)

  if (response) {
    yield put({
      type: actions.FETCH_CITIES,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/cities')
    notification.success({
      message: 'Update Success',
      description: 'You have successfully update a city!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateLoading: false,
      },
    })
  }
}

export function* deleteCity({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      deleteLoading: true,
    },
  })

  const response = yield call(CitiesApi.deleteCity, id)

  if (response) {
    yield put({
      type: actions.FETCH_CITIES,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/cities')
    notification.success({
      message: 'Delete Success',
      description: 'You have successfully delete a city!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        deleteLoading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CITIES, fetchCities),
    takeEvery(actions.CREATE_CITY, createCity),
    takeEvery(actions.RETRIEVE_CITY, retrieveCity),
    takeEvery(actions.UPDATE_CITY, updateCity),
    takeEvery(actions.DELETE_CITY, deleteCity)
  ])
}
