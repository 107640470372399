import apiClient from './index'

const baseUrl = '/users';

export async function fetchUsers(limit = 10, offset, search) {
  return apiClient
    .get(baseUrl, {
      params: { limit, offset, search }
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function fetchKYC() {
  return apiClient
    .get(`${baseUrl}/kyc`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function fetchAnalytics() {
  return apiClient
    .get(`${baseUrl}/analytics`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function fetchTotalData() {
  return apiClient
    .get(`${baseUrl}/total`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function retrieveUser(id) {
  return apiClient
    .get(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function createUser(data) {
  return apiClient
    .post(baseUrl, data, {
      'content-type': 'multipart/form-data'
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function updateUser(id, data) {
  return apiClient
    .put(`${baseUrl}/${id}`, data, {
      'content-type': 'multipart/form-data'
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function deleteUser(id) {
  return apiClient
    .delete(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function blockUser(userId, blockUserId) {
  return apiClient
    .post(`${baseUrl}/${userId}/block`, { user: blockUserId })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err))
}

export async function unblockUser(userId, unblockUserId) {
  return apiClient
    .post(`${baseUrl}/${userId}/unblock`, { user: unblockUserId })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err))
}

export async function verifyEmail(userId, status) {
  return apiClient
    .post(`${baseUrl}/${userId}/verifyEmail`, { status })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err))
}

export async function setKycStatus(userId, status) {
  return apiClient
    .post(`${baseUrl}/${userId}/kyc`, { status })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err))
}
