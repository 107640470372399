import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  {
    path: '/users',
    Component: lazy(() => import('pages/users/UserListPage')),
    exact: true,
    role: 'USER_EDIT'
  },
  {
    path: '/users/create',
    Component: lazy(() => import('pages/users/UserEditPage')),
    exact: true,
    role: 'USER_EDIT'
  },
  {
    path: '/users/:id/edit',
    Component: lazy(() => import('pages/users/UserEditPage')),
    exact: true,
    role: 'USER_EDIT'
  },
  {
    path: '/users/:id',
    Component: lazy(() => import('pages/users/UserDetailPage')),
    exact: true,
    role: 'USER_EDIT'
  },
  {
    path: '/chats/private',
    Component: lazy(() => import('pages/chats/ChatListPage')),
    exact: true,
    role: 'CHAT_EDIT'
  },
  {
    path: '/chats/public',
    Component: lazy(() => import('pages/chats/PublicChatListPage')),
    exact: true,
    role: 'CHAT_EDIT'
  },
  {
    path: '/chats/group',
    Component: lazy(() => import('pages/chats/GroupChatListPage')),
    exact: true,
    role: 'CHAT_EDIT'
  },
  {
    path: '/chats/create',
    Component: lazy(() => import('pages/chats/ChatEditPage')),
    exact: true,
    role: 'CHAT_EDIT'
  },
  {
    path: '/chats/:id',
    Component: lazy(() => import('pages/chats/ChatDetailPage')),
    exact: true,
    role: 'CHAT_EDIT'
  },
  {
    path: '/chats/:id/edit',
    Component: lazy(() => import('pages/chats/ChatEditPage')),
    exact: true,
    role: 'CHAT_EDIT'
  },
  {
    path: '/posts',
    Component: lazy(() => import('pages/posts/PostListPage')),
    exact: true,
    role: 'POST_EDIT'
  },
  {
    path: '/posts/create',
    Component: lazy(() => import('pages/posts/PostEditPage')),
    exact: true,
    role: 'POST_EDIT'
  },
  {
    path: '/posts/:id',
    Component: lazy(() => import('pages/posts/PostDetailPage')),
    exact: true,
    role: 'POST_EDIT'
  },
  {
    path: '/posts/:id/edit',
    Component: lazy(() => import('pages/posts/PostEditPage')),
    exact: true,
    role: 'POST_EDIT'
  },
  {
    path: '/comments',
    Component: lazy(() => import('pages/comments/CommentListPage')),
    exact: true,
    role: 'COMMENT_EDIT'
  },
  {
    path: '/comments/create',
    Component: lazy(() => import('pages/comments/CommentEditPage')),
    exact: true,
    role: 'COMMENT_EDIT'
  },
  {
    path: '/comments/:id/edit',
    Component: lazy(() => import('pages/comments/CommentEditPage')),
    exact: true,
    role: 'COMMENT_EDIT'
  },
  {
    path: '/kyc',
    Component: lazy(() => import('pages/kyc/KYCListPage')),
    exact: true,
    role: 'KYC_EDIT'
  },
  {
    path: '/kyc/:id',
    Component: lazy(() => import('pages/kyc/KYCDetailPage')),
    exact: true,
    role: 'KYC_EDIT'
  },
  {
    path: '/ads',
    Component: lazy(() => import('pages/ads/AdListPage')),
    exact: true,
    role: 'POST_EDIT'
  },
  {
    path: '/ads/:id',
    Component: lazy(() => import('pages/ads/AdDetailPage')),
    exact: true,
    role: 'POST_EDIT'
  },
  {
    path: '/ads/:id/edit',
    Component: lazy(() => import('pages/posts/PostEditPage')),
    exact: true,
    role: 'POST_EDIT'
  },
  {
    path: '/cities',
    Component: lazy(() => import('pages/cities/CityListPage')),
    exact: true,
    role: 'CITY_EDIT'
  },
  {
    path: '/settings/account',
    Component: lazy(() => import('pages/settings/AccountInfo')),
    exact: true,
    role: 'ACCOUNT_EDIT'
  },
  {
    path: '/settings/security',
    Component: lazy(() => import('pages/settings/Security')),
    exact: true,
    role: 'ACCOUNT_EDIT'
  },
  {
    path: '/settings/sub-accounts',
    Component: lazy(() => import('pages/settings/Accounts')),
    exact: true,
    role: 'ACCOUNT_EDIT'
  },
  {
    path: '/auth-tool',
    Component: lazy(() => import('pages/authTool/index')),
    exact: true,
    role: 'ACCOUNT_EDIT'
  },
  {
    path: '/api-key',
    Component: lazy(() => import('pages/apiKey/index')),
    exact: true,
    role: 'ACCOUNT_EDIT'
  },
  {
    path: '/chats/public/categories/:id/chats',
    Component: lazy(() => import('pages/categories/CategoryChatsPage')),
    exact: true,
    role: 'CHAT_EDIT'
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/reset-password/:token',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/auth/2fa',
    Component: lazy(() => import('pages/auth/tfa')),
    exact: true,
  },
  {
    path: '/auth/admin/set-password/:token',
    Component: lazy(() => import('pages/auth/admin-password')),
    exact: true,
  },
  {
    path: '/email-verification/:id',
    Component: lazy(() => import('pages/emailVerification')),
    exact: true,
  },
  {
    path: '/mobile/verification/:token',
    Component: lazy(() => import('pages/auth/mobile-verification')),
    exact: true,
  }
]

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  permissions: user.permissions,
  role: user.role
})

const Router = ({ history, routerAnimation, permissions, role }) => {

  const filterRoutes = () => {
    if (role === 'super_admin')
      return routes;
    return routes.filter(route => {
      if (!route.role) return true;

      return permissions.indexOf(route.role) !== -1;
    })
  }

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {filterRoutes().map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
