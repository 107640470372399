import apiClient from './index'

const baseUrl = '/chat';

export async function fetchChats(payload) {
  let limit = 10;
  let offset = 0;
  let user;
  let search;
  let type;

  if (payload) {
    const { page, pageSize, user: userId, search: searchTerm = '', type: typeT = '' } = payload;

    limit = pageSize ? parseInt(pageSize, 10) : 10;
    offset = (page && pageSize) ? (parseInt(page, 10) - 1) * (limit) : 0;

    if (userId) {
      user = userId;
    }
    search = searchTerm;
    type = typeT;
  }

  return apiClient
    .get(baseUrl, {
      params: { limit, offset, user, search, type }
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function retrieveChat(id) {
  return apiClient
    .get(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function createChat(data) {
  return apiClient
    .post(baseUrl, data)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function updateChat(id, data) {
  return apiClient
    .put(`${baseUrl}/${id}`, data)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function deleteChat(id) {
  return apiClient
    .delete(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function fetchMessagesByChat(payload) {
  const { chat } = payload;

  return apiClient
    .get(`/messages`, {
      params: { chatId: chat }
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}
