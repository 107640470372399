import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'

import * as AuthApi from '../../services/api/auth.api';
import * as AccountApi from '../../services/api/accounts.api';

import actions from './actions'

export function* updatePassword({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updateLoading: true,
    },
  })

  const response = yield call(AuthApi.updatePassword, payload)

  if (response) {
    notification.success({
      message: 'Update Password Success',
      description: 'You have successfully updated password!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateLoading: false,
      },
    })
  }
}

export function* fetch2FA() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      tfaLoading: true,
    },
  })

  const response = yield call(AuthApi.fetch2FA)

  if (response) {
    const { qr } = response;
    yield put({
      type: actions.SET_STATE,
      payload: {
        tfaLoading: false,
        qr
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        tfaLoading: false,
      },
    })
  }
}

export function* disable2FA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      tfaDisabling: true,
    },
  })

  const response = yield call(AuthApi.disable2FA, payload)

  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        tfaEnabled: false,
      },
    })
    notification.success({
      message: 'Disable TFA Success',
      description: 'You have successfully disabled TFA!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        tfaDisabling: false,
      },
    })
  }
}

export function* enable2FA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      tfaEnabling: true,
    },
  })

  const response = yield call(AuthApi.enable2FA, payload)

  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        tfaEnabled: true,
      },
    })
    notification.success({
      message: 'Enable TFA Success',
      description: 'You have successfully enabled TFA!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        tfaEnabling: false,
      },
    })
  }
}

export function* fetchActionHistories() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      historiesLoading: true,
    },
  })

  const response = yield call(AuthApi.fetchActionHistories)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        actionHistories: response.data,
        historiesLoading: false,
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        historiesLoading: false,
      },
    })
  }
}

export function* fetchAccounts({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      accountsLoading: true,
    },
  })

  const response = yield call(AccountApi.fetchAccounts, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accounts: response.data,
        totalCount: response.totalCount,
        accountsLoading: false,
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountsLoading: false,
      },
    })
  }
}

export function* createAccount({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      createLoading: true,
    },
  })

  const response = yield call(AccountApi.createAccount, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        createLoading: false,
      },
    })
    yield put({
      type: actions.FETCH_ACCOUNTS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })

    notification.success({
      message: 'Create Success',
      description: 'You have successfully create a account!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        createLoading: false,
      },
    })
  }
}

export function* deleteAccount({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      deleteLoading: true,
    },
  })

  const response = yield call(AccountApi.deleteAccount, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        deleteLoading: false,
      },
    })
    yield put({
      type: actions.FETCH_ACCOUNTS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })

    notification.success({
      message: 'Delete Success',
      description: 'You have successfully delete a account!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        deleteLoading: false,
      },
    })
  }
}

export function* retrieveAccount({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      retrieveLoading: true,
    },
  })

  const response = yield call(AccountApi.retrieveAccount, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        account: response,
        retrieveLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        retrieveLoading: false,
      },
    })
  }
}

export function* updateAccount({ payload }) {
  const { id, body } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      updateLoading: true,
    },
  })

  const response = yield call(AccountApi.updateAccount, id, body)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateLoading: false,
      },
    })
    yield put({
      type: actions.FETCH_ACCOUNTS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })

    notification.success({
      message: 'Update Success',
      description: 'You have successfully update a account!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateLoading: false,
      },
    })
  }
}

export function* updateProfile({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updateProfileLoading: true,
    },
  })

  const response = yield call(AccountApi.updateProfile,payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateProfileLoading: false,
      },
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    notification.success({
      message: 'Update Success',
      description: 'You have successfully update profile!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateProfileLoading: false,
      },
    })
  }
}


export function* setAdminPassword({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      setAdminPasswordLoading: true,
    },
  })

  const response = yield call(AuthApi.setAdminPassword, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        setAdminPasswordLoading: false,
      },
    })

    notification.success({
      message: 'Set Password Success',
      description: 'You have successfully set password!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        setAdminPasswordLoading: false,
      },
    })
  }
}

export function* setApiKey({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      setApiKeyLoading: true,
    },
  })

  const response = yield call(AccountApi.setApiKey, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        apiKey: response.apiKey,
        setApiKeyLoading: false,
      },
    })

    notification.success({
      message: 'Set Api Key Success',
      description: 'You have successfully set api key!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        setApiKeyLoading: false,
      },
    })
  }
}

export function* fetchApiKey() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetchApiKeyLoading: true,
    },
  })

  const response = yield call(AccountApi.fetchApiKey)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        apiKey: response.apiKey,
        fetchApiKeyLoading: false,
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetchApiKeyLoading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_PASSWORD, updatePassword),
    takeEvery(actions.FETCH_2FA, fetch2FA),
    takeEvery(actions.DISABLE_2FA, disable2FA),
    takeEvery(actions.ENABLE_2FA, enable2FA),
    takeEvery(actions.FETCH_ACTION_HISTORIES, fetchActionHistories),
    takeEvery(actions.FETCH_ACCOUNTS, fetchAccounts),
    takeEvery(actions.CREATE_ACCOUNT, createAccount),
    takeEvery(actions.DELETE_ACCOUNT, deleteAccount),
    takeEvery(actions.RETRIEVE_ACCOUNT, retrieveAccount),
    takeEvery(actions.UPDATE_ACCOUNT, updateAccount),
    takeEvery(actions.SET_ADMIN_PASSWORD, setAdminPassword),
    takeEvery(actions.UPDATE_PROFILE, updateProfile),
    takeEvery(actions.SET_API_KEY, setApiKey),
    takeEvery(actions.FETCH_API_KEY, fetchApiKey)
  ])
}
