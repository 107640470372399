import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'

import * as GroupsApi from '../../services/api/groups.api';

import actions from './actions'

export function* fetchGroups({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(GroupsApi.fetchGroups, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        groups: response.data,
        totalCount: response.totalCount,
        loading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* createGroup({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      createLoading: true,
    },
  })

  const response = yield call(GroupsApi.createGroup, payload)

  if (response) {
    yield put({
      type: actions.FETCH_GROUPS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/groups')
    notification.success({
      message: 'Create Success',
      description: 'You have successfully create a group!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        createLoading: false,
      },
    })
  }
}

export function* retrieveGroup({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      retrieveLoading: true,
    },
  })

  const response = yield call(GroupsApi.retrieveGroup, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        group: response,
        retrieveLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        retrieveLoading: false,
      },
    })
  }
}

export function* updateGroup({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updateLoading: true,
    },
  })

  const { id, body } = payload;

  const response = yield call(GroupsApi.updateGroup, id, body)

  if (response) {
    yield put({
      type: actions.FETCH_GROUPS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/groups')
    notification.success({
      message: 'Update Success',
      description: 'You have successfully update a group!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateLoading: false,
      },
    })
  }
}

export function* deleteGroup({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      deleteLoading: true,
    },
  })

  const response = yield call(GroupsApi.deleteGroup, id)

  if (response) {
    yield put({
      type: actions.FETCH_GROUPS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/groups')
    notification.success({
      message: 'Delete Success',
      description: 'You have successfully delete a group!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        deleteLoading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_GROUPS, fetchGroups),
    takeEvery(actions.CREATE_GROUP, createGroup),
    takeEvery(actions.RETRIEVE_GROUP, retrieveGroup),
    takeEvery(actions.UPDATE_GROUP, updateGroup),
    takeEvery(actions.DELETE_GROUP, deleteGroup)
  ])
}
