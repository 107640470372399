import apiClient from './index'

const baseUrl = '/account';

export async function fetchAccounts(payload) {
  let limit = 10;
  let offset = 0;
  let search;

  if (payload) {
    const { page, pageSize, search: searchTerm = '' } = payload;

    limit = pageSize ? parseInt(pageSize, 10) : 10;
    offset = (page && pageSize) ? (parseInt(page, 10) - 1) * (limit) : 0;

    search = searchTerm;
  }

  return apiClient
    .get(baseUrl, {
      params: { limit, offset, search }
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function createAccount(payload) {
  return apiClient
    .post(baseUrl, payload)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function deleteAccount(id) {
  return apiClient
    .delete(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function retrieveAccount(id) {
  return apiClient
    .get(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function updateAccount(id, payload) {
  return apiClient
    .put(`${baseUrl}/${id}`, payload)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function updateProfile(payload) {
  return apiClient
    .post(`${baseUrl}/profile`, payload)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function setApiKey(payload) {
  return apiClient
    .post(`${baseUrl}/api-key`, payload)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function fetchApiKey() {
  return apiClient
    .get(`${baseUrl}/api-key`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}
