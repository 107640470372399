import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'

import actions from './actions'
import * as ChatApi from '../../services/api/chats.api';

export function* fetchChats({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(ChatApi.fetchChats, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        chats: response.data,
        totalCount: response.totalCount,
        loading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* retrieveChat({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      retrieveLoading: true,
    },
  })

  const response = yield call(ChatApi.retrieveChat, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        curChat: response,
        retrieveLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        retrieveLoading: false,
      },
    })
  }

}

export function* createChat({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      createLoading: true,
    },
  })

  const response = yield call(ChatApi.createChat, payload)

  if (response) {
    yield put({
      type: actions.FETCH_CHATS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/chats')
    notification.success({
      message: 'Create Success',
      description: 'You have successfully create a chat!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        createLoading: false,
      },
    })
  }
}

export function* updateChat({ payload }) {
  const { id, body } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      updateLoading: true,
    },
  })

  const response = yield call(ChatApi.updateChat, id, body)

  if (response) {
    history.goBack();
    notification.success({
      message: 'Update Success',
      description: 'You have successfully update a user!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateLoading: false,
      },
    })
  }
}

export function* deleteChat({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      deleteLoading: true,
    },
  })

  const response = yield call(ChatApi.deleteChat, id)

  if (response) {
    yield put({
      type: actions.FETCH_CHATS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/chats')
    notification.success({
      message: 'Delete Success',
      description: 'You have successfully delete a chat!',
    })
  }
  if (!response) {
    yield put({
      type: actions.DELETE_CHAT,
      payload: {
        deleteLoading: false,
      },
    })
  }
}

export function* fetchMessagesByChat({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetchMessagingByChat: true,
    },
  })

  const response = yield call(ChatApi.fetchMessagesByChat, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        messages: response.data,
        messagesCount: response.totalCount,
        fetchMessagingByChat: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetchMessagingByChat: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CHATS, fetchChats),
    takeEvery(actions.RETRIEVE_CHAT, retrieveChat),
    takeEvery(actions.CREATE_CHAT, createChat),
    takeEvery(actions.UPDATE_CHAT, updateChat),
    takeEvery(actions.DELETE_CHAT, deleteChat),
    takeEvery(actions.FETCH_MESSAGES_BY_CHAT, fetchMessagesByChat)
  ])
}
