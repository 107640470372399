import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'

import * as CommentsApi from '../../services/api/comments.api';

import actions from './actions'

export function* fetchComments({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(CommentsApi.fetchComments, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        comments: response.data,
        totalCount: response.totalCount,
        loading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* createComment({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      createLoading: true,
    },
  })

  const response = yield call(CommentsApi.createComment, payload)

  if (response) {
    yield put({
      type: actions.FETCH_COMMENTS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/comments')
    notification.success({
      message: 'Create Success',
      description: 'You have successfully create a comment!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        createLoading: false,
      },
    })
  }
}

export function* retrieveComment({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      retrieveLoading: true,
    },
  })

  const response = yield call(CommentsApi.retrieveComment, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        comment: response,
        retrieveLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        retrieveLoading: false,
      },
    })
  }
}

export function* updateComment({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updateLoading: true,
    },
  })

  const { id, body } = payload;

  const response = yield call(CommentsApi.updateComment, id, body)

  if (response) {
    yield put({
      type: actions.FETCH_COMMENTS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/comments')
    notification.success({
      message: 'Update Success',
      description: 'You have successfully update a comment!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateLoading: false,
      },
    })
  }
}

export function* deleteComment({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      deleteLoading: true,
    },
  })

  const response = yield call(CommentsApi.deleteComment, id)

  if (response) {
    yield put({
      type: actions.FETCH_COMMENTS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/comments')
    notification.success({
      message: 'Delete Success',
      description: 'You have successfully delete a comment!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        deleteLoading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_COMMENTS, fetchComments),
    takeEvery(actions.CREATE_COMMENT, createComment),
    takeEvery(actions.RETRIEVE_COMMENT, retrieveComment),
    takeEvery(actions.UPDATE_COMMENT, updateComment),
    takeEvery(actions.DELETE_COMMENT, deleteComment)
  ])
}
