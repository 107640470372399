import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import chats from './chats/reducers'
import posts from './posts/reducers'
import comments from './comments/reducer'
import cities from './cities/reducer'
import accounts from './accounts/reducer';
import categories from './categories/reducer';
import groups from './groups/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    chats,
    posts,
    comments,
    cities,
    accounts,
    categories,
    groups
  })
