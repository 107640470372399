const actions = {
  SET_STATE: 'chats/SET_STATE',
  FETCH_CHATS: 'FETCH_CHATS',
  RETRIEVE_CHAT: 'RETRIEVE_CHAT',
  CREATE_CHAT: 'CREATE_CHAT',
  UPDATE_CHAT: 'UPDATE_CHAT',
  DELETE_CHAT: 'DELETE_CHAT',
  FETCH_MESSAGES_BY_CHAT: 'FETCH_MESSAGES_BY_CHAT'
}

export default actions
