import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import { history } from 'index'

import env from '../../env'

const apiClient = axios.create({
  baseURL: env.API_URL || 'http://localhost:1337/api/v2/admin',
})

apiClient.interceptors.request.use(request => {
  if (request.url === "/auth/login/tfa") {
    return request;
  }

  const accessToken = store.get('accessToken')

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  let message = ''

  if (window.location.href.indexOf('auth') === -1 && response.status === 401) {
    notification.warning({
      message: data
    });
    store.remove('accessToken');
    history.push('/auth/login');
  }

  if (data) {
    if (data.message) {
      message = data.message ? data.message : '';
    } else if (data.error) {
      message = data.error.message ? data.error.message : '';
    }
  }

  if (message && typeof message === 'string') {
    notification.warning({
      message
    })
  }

  notification.warning({
    message: `${response.status} Error`
  })
})

export default apiClient
