import apiClient from './index'

const baseUrl = '/cities';

export async function fetchCities(payload) {
  let limit = 10;
  let offset = 0;

  if (payload) {
    const { page, pageSize } = payload;

    if (pageSize) {
      limit = parseInt(pageSize, 10)
    }

    if (page && pageSize) {
      offset = (parseInt(page, 10) - 1) * (limit);
    }
  }

  return apiClient
    .get(baseUrl, {
      params: { limit, offset, search: payload ? payload.search : '' }
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function retrieveCity(id) {
  return apiClient
    .get(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function createCity(data) {
  const { name, longitude, latitude, area } = data;

  return apiClient
    .post(baseUrl, {
      name,
      area,
      location: {
        coordinates: [longitude, latitude]
      }
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function updateCity(id, data) {
  const { name, longitude, latitude, area } = data;

  return apiClient
    .put(`${baseUrl}/${id}`, {
      name,
      area,
      location: {
        coordinates: [longitude, latitude]
      }
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function deleteCity(id) {
  return apiClient
    .delete(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}
