import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import chats from './chats/sagas'
import posts from './posts/sagas'
import comments from './comments/sagas'
import cities from './cities/sagas'
import accounts from './accounts/sagas';
import categories from './categories/sagas';
import groups from './groups/sagas';

export default function* rootSaga() {
  yield all([user(), menu(), settings(), chats(), posts(), comments(), cities(), accounts(), categories(), groups()])
}
