const actions = {
  SET_STATE: 'posts/SET_STATE',
  FETCH_POSTS: 'FETCH_POSTS',
  RETRIEVE_POST: 'RETRIEVE_POST',
  CREATE_POST: 'CREATE_POST',
  UPDATE_POST: 'UPDATE_POST',
  DELETE_POST: 'DELETE_POST',
  FETCH_ADS: 'FETCH_ADS',
  POST_COMMENT: 'POST_COMMENT',
  SWITCH_POST: 'SWITCH_POST',
  HIDE_POST: 'HIDE_POST',
  SHOW_POST: 'SHOW_POST',
  SET_PROMOTED_POST: 'SET_PROMOTED_POST'
}

export default actions
