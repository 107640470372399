export default async function getMenuDataNew() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboardMomunity',
      icon: 'fe fe-home',
      url: '/dashboard',
    },
    {
      title: 'Users',
      key: 'usersPage',
      icon: 'fe fe-users',
      url: '/users',
      role: 'USER_EDIT'
    },
    {
      title: 'Chats',
      key: 'chatsPage',
      icon: 'fa fa-wechat',
      role: 'CHAT_EDIT',
      children: [
        {
          title: 'Private Chats',
          key: 'private chats',
          url: '/chats/private',
        },
        {
          title: 'Public Chats',
          key: 'public chats',
          url: '/chats/public',
        },
        {
          title: 'Group Chats',
          key: 'group chats',
          url: '/chats/group',
        }
      ]
    },
    {
      title: 'Posts',
      key: 'postsPage',
      icon: 'fa fa-edit',
      url: '/posts',
      role: 'POST_EDIT',
    },
    {
      title: 'Ads',
      key: 'adsPage',
      icon: 'fa fa-audio-description',
      url: '/ads',
      role: 'POST_EDIT',
    },
    {
      title: 'Comments',
      key: 'commentsPage',
      icon: 'fa fa-comments-o',
      url: '/comments',
      role: 'COMMENT_EDIT',
    },
    {
      title: 'Cities',
      key: 'citiesPage',
      icon: 'fa fa-globe',
      url: '/cities',
      role: 'CITY_EDIT',
    },
    {
      title: 'KYC',
      key: 'kycPage',
      url: '/kyc',
      icon: 'fe fe-users',
      role: 'KYC_EDIT',
    },
    {
      title: 'Auth Tool',
      key: 'authenticationToolPage',
      icon: 'fe fe-user',
      url: '/auth-tool',
      role: 'ACCOUNT_EDIT',
    },
    {
      title: 'Api Key',
      key: 'apiKeyPage',
      icon: 'fe fe-user',
      url: '/api-key',
      role: 'ACCOUNT_EDIT',
    },
    {
      title: 'Settings',
      key: 'settingsPage',
      icon: 'fe fe-settings',
      role: 'ACCOUNT_EDIT',
      children: [
        {
          title: 'My Account',
          key: 'account',
          url: '/settings/account',
        },
        {
          title: 'Security',
          key: 'security',
          url: '/settings/security',
        },
        {
          title: 'Sub Accounts',
          key: 'subAccounts',
          url: '/settings/sub-accounts',
        },
      ]
    },
  ]
}
