import React from 'react'
import { connect } from 'react-redux'
import style from '../style.module.scss'

const mapStateToProps = ({ dispatch }) => ({
  dispatch,
})

const Logout = ({ dispatch }) => {
  const handleLogout = () => {
    dispatch({
      type: 'user/LOGOUT'
    })
  }

  return (
    <div className='font-size-32 kit__utils__link mr-lg-3 mr-md-2'>
      <button
        className={style.button}
        onClick={handleLogout}
        onFocus={(e) => { e.preventDefault() }}
        onKeyDown={() => {}}
        type='button'
      >
        <i className='fa fa-sign-in' />
      </button>
    </div>
  )
}

export default connect(mapStateToProps)(Logout)
