import apiClient from './index'

const baseUrl = '/posts';

export async function fetchPosts(payload) {
  let paramData;
  if (!payload) {
    paramData = { limit: 10, offset: 0 };
  } else {
    let limit = 10;
    let offset = 0;

    const { page, pageSize, ...other } = payload;

    if (pageSize) {
      limit = parseInt(pageSize, 10)
    }

    if (page && pageSize) {
      offset = (parseInt(page, 10) - 1) * (limit);
    }
    paramData = { limit, offset, ...other };
  }

  return apiClient
    .get(baseUrl, {
      params: paramData
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function retrievePost(id) {
  return apiClient
    .get(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function createPost(data) {
  return apiClient
    .post(baseUrl, data, {
      'content-type': 'multipart/form-data'
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function updatePost(id, data) {
  return apiClient
    .put(`${baseUrl}/${id}`, data, {
      'content-type': 'multipart/form-data'
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function deletePost(id) {
  return apiClient
    .delete(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function switchPost(postId, type) {
  return apiClient
    .post(`${baseUrl}/${postId}/switch`, { type })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function hidePost(postId) {
  return apiClient
    .post(`${baseUrl}/${postId}/hide`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function showPost(postId) {
  return apiClient
    .post(`${baseUrl}/${postId}/show`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function promotedPost(postId, position) {
  return apiClient
    .post(`${baseUrl}/${postId}/promoted`, { position })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}
