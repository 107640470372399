import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Menu } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import store from 'store'
import style from './style.module.scss'
import ThemeSwitch from '../../../common/ThemeSwitch'
import Logout from '../../../system/Auth/Logout'

const mapStateToProps = ({ menu, settings, user, dispatch }) => ({
  menuData: menu.menuData,
  logo: settings.logo,
  menuColor: settings.menuColor,
  role: user.role,
  firstName: user.firstName,
  lastName: user.lastName,
  permissions: user.permissions,
  dispatch
})

const MenuTop = ({
  menuData = [],
  location: { pathname },
  menuColor,
  logo,
  firstName,
  lastName,
  role,
  permissions
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

  useEffect(() => {
    const pathUrl = pathname.split('/').slice(0, 2).join('/');
    const path = menuData.find(item => item.url === pathUrl);

    if (path && path.key) {
      setSelectedKeys(path.key);
    }
  }, [pathname, menuData])

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return null
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${style.icon}`} />}
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                {icon && <span className={`${icon} ${style.icon}`} />}
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {icon && <span className={`${icon} ${style.icon}`} />}
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    const filterMenuData = menuData.filter(item => {
      if (!item.role) return true;

      if (role === 'super_admin') return true;

      return permissions.indexOf(item.role) !== -1;
    })

    return filterMenuData.map(menuItem => {
      // if (menuItem.roles && !menuItem.roles.includes(role)) {
      //   return null
      // }

      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  return (
    <div
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
      })}
    >
      <div className={style.logoContainer}>
        <div className={style.logo}>
          <img src="resources/images/logo.svg" className="mr-2" alt="Momunity" />
          <div className={style.name}>{logo}</div>
        </div>
      </div>
      <div className={style.navigation}>
        <Menu onClick={handleClick} selectedKeys={selectedKeys} mode="horizontal">
          {generateMenuItems()}
        </Menu>
      </div>
      <div className="d-flex align-items-center">
        <span className="font-size-18 mr-3">{`${firstName} ${lastName}`}</span>
        <Logout />
        <ThemeSwitch />
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MenuTop))
