import apiClient from './index'

const baseUrl = '/categories';

export async function fetchCategories() {
  return apiClient
    .get(baseUrl)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function fetchCategoryChats(payload) {
  const { id } = payload;

  return apiClient
    .get(`${baseUrl}/${id}/chats`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function retrieveCategory(payload) {
  const { id } = payload;

  return apiClient
    .get(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}
