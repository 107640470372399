const actions = {
  SET_STATE: 'groups/SET_STATE',
  FETCH_GROUPS: 'FETCH_GROUPS',
  RETRIEVE_GROUP: 'RETRIEVE_GROUP',
  CREATE_GROUP: 'CREATE_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
}

export default actions
