import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import store from 'store'
import { history } from 'index'

import * as AuthApi from '../../services/api/auth.api';
import * as UserApi from '../../services/api/users.api';
import * as UserAuthApi from '../../services/api/user-auth.api';

import actions from './actions'

export function* login({ payload }) {
  const { email, password } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(AuthApi.login, email, password)

  if (response) {
    const { token, message } = response

    if (message && message === 'TFA Enabled') {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          tfaToken: token
        },
      })
      yield history.push('/auth/2fa');
    } else if (token && token.accessToken) {
      store.set('accessToken', token.accessToken)

      yield put({
        type: actions.LOAD_CURRENT_ACCOUNT,
      })
      yield history.push('/')
      notification.success({
        message: 'Logged In',
        description: 'You have successfully logged in!',
      })
    }
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* forgotPassword({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      forgotPasswordLoading: true,
    },
  })

  const response = yield call(AuthApi.forgotPassword, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        forgotPasswordLoading: false
      }
    })

    notification.success({
      message: 'Request Success',
      description: 'You have successfully requested reset password!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        forgotPasswordLoading: false,
      },
    })
  }
}

export function* resetPassword({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetPasswordLoading: true,
    },
  })

  const response = yield call(AuthApi.resetPassword, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        resetPasswordLoading: false
      }
    })

    notification.success({
      message: 'Reset Success',
      description: 'You have successfully reset password!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        resetPasswordLoading: false,
      },
    })
  }
}
export function* tfaLogin({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      tfaLoading: true,
    },
  })

  const response = yield call(AuthApi.tfaLogin, payload)

  if (response) {
    const { token } = response;

    store.set('accessToken', token.accessToken)

    yield put({
      type: actions.LOAD_CURRENT_ACCOUNT,
    })

    yield history.push('/')
    notification.success({
      message: '2FA Logged In',
      description: 'You have successfully 2FA logged in!',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        tfaLoading: false,
      },
    })
  }
}

export function* registerUser({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      userRegistering: true,
    },
  })

  const response = yield call(UserAuthApi.register, payload);
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        user: response,
        userRegistering: false
      },
    })
    notification.success({
      message: 'User Register Success',
      description: 'You have successfully registered a user!',
    })
  }

  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        userRegistering: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  if (!store.get('accessToken')) {
    return ;
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(AuthApi.currentAccount)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...response,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(AuthApi.logout);

  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* fetchUsers({ payload }) {
  const { page, pageSize, search } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      fetchUsersLoading: true,
    },
  })

  const response = yield call(UserApi.fetchUsers, pageSize, (page - 1) * pageSize, search)

  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        users: response.data,
        totalCount: response.totalCount,
        fetchUsersLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        fetchUsersLoading: false,
      },
    })
  }
}

export function* fetchAnalytics() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      fetchAnalyticsLoading: true,
    },
  })
  const response = yield call(UserApi.fetchAnalytics)

  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        analytics: response,
        fetchAnalyticsLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        fetchAnalyticsLoading: false,
      },
    })
  }
}

export function* retrieveUser({ payload }) {
  const { id } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      retrieveUserLoading: true,
    },
  })

  const response = yield call(UserApi.retrieveUser, id)

  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        user: response,
        retrieveUserLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        retrieveUserLoading: false,
      },
    })
  }

}

export function* createUser({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      createUserLoading: true,
    },
  })

  const response = yield call(UserApi.createUser, payload)

  if (response) {
    yield put({
      type: 'FETCH_USERS',
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/users')
    notification.success({
      message: 'Create Success',
      description: 'You have successfully create a user!',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        createUserLoading: false,
      },
    })
  }
}

export function* updateUser({ payload }) {
  const { id, body } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      updateUserLoading: true,
    },
  })

  const response = yield call(UserApi.updateUser, id, body)

  if (response) {
    yield put({
      type: 'FETCH_USERS',
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/users')
    notification.success({
      message: 'Update Success',
      description: 'You have successfully update a user!',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        updateUserLoading: false,
      },
    })
  }
}

export function* deleteUser({ payload }) {
  const { id } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      deleteUserLoading: true,
    },
  })

  const response = yield call(UserApi.deleteUser, id)

  if (response) {
    yield put({
      type: 'FETCH_USERS',
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/users')
    notification.success({
      message: 'Delete Success',
      description: 'You have successfully delete a user!',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        deleteUserLoading: false,
      },
    })
  }

}

export function* blockUser({ payload }) {
  const { userId, blockUserId } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      blockLoading: true,
    },
  })

  const response = yield call(UserApi.blockUser, userId, blockUserId)

  if (response) {
    yield put({
      type: actions.RETRIEVE_USER,
      payload: {
        id: userId
      }
    })
    notification.success({
      message: 'Block User Success',
      description: 'You have successfully blocked a user!',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        blockLoading: false,
      },
    })
  }
}

export function* unblockUser({ payload }) {
  const { userId, unblockUserId } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      unblockLoading: true,
    },
  })

  const response = yield call(UserApi.unblockUser, userId, unblockUserId)

  if (response) {
    yield put({
      type: actions.RETRIEVE_USER,
      payload: {
        id: userId
      }
    })
    notification.success({
      message: 'Unblock User Success',
      description: 'You have successfully unblocked a user!',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        unblockLoading: false,
      },
    })
  }
}

export function* verifyEmail({ payload }) {
  const { userId, status } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      verifyEmailLoading: true,
    },
  })

  const response = yield call(UserApi.verifyEmail, userId, status)

  if (response) {
    yield put({
      type: actions.RETRIEVE_USER,
      payload: {
        id: userId
      }
    })

    if (status) {
      notification.success({
        message: 'Verify Email Success',
        description: 'You have successfully verified email!',
      })
    } else {
      notification.success({
        message: 'Cancel Verifying Email Success',
        description: 'You have successfully canceled verifying email!',
      })
    }
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        verifyEmailLoading: false,
      },
    })
  }
}

export function* setKycStatus({ payload }) {
  const { userId, status, detail } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      setKycStatusLoading: true,
    },
  })

  const response = yield call(UserApi.setKycStatus, userId, status)

  if (response) {
    if (detail) {
      yield put({
        type: actions.RETRIEVE_USER,
        payload: {
          id: userId
        }
      })
    } else {
      yield put({
        type: actions.FETCH_KYC,
      })
    }

    notification.success({
      message: 'Set KYC Status Success',
      description: 'You have successfully set KYC status!',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        setKycStatusLoading: false,
      },
    })
  }
}

export function* deleteKYC({ payload }) {
  const { id } = payload;

  yield put({
    type: 'user/SET_STATE',
    payload: {
      deleteUserLoading: true,
    },
  })

  const response = yield call(UserApi.deleteUser, id)

  if (response) {
    yield put({
      type: 'FETCH_USERS',
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/kyc')
    notification.success({
      message: 'Delete KYC',
      description: 'You have successfully delete a kyc!',
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        deleteUserLoading: false,
      },
    })
  }

}

export function* fetchTotalData() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      fetchTotalDataLoading: true,
    },
  })
  const response = yield call(UserApi.fetchTotalData)

  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        totalData: response.data,
        fetchTotalDataLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        fetchTotalDataLoading: false,
      },
    })
  }
}

export function* fetchKyc() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      fetchKycLoading: true,
    },
  })

  const response = yield call(UserApi.fetchKYC)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        kycs: response,
        fetchKycLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        fetchKycLoading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, login),
    takeEvery(actions.TFA_LOGIN, tfaLogin),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FETCH_USERS, fetchUsers),
    takeEvery(actions.FETCH_ANALYTICS, fetchAnalytics),
    takeEvery(actions.RETRIEVE_USER, retrieveUser),
    takeEvery(actions.CREATE_USER, createUser),
    takeEvery(actions.UPDATE_USER, updateUser),
    takeEvery(actions.DELETE_USER, deleteUser),
    takeEvery(actions.BLOCK_USER, blockUser),
    takeEvery(actions.UNBLOCK_USER, unblockUser),
    takeEvery(actions.VERIFY_EMAIL, verifyEmail),
    takeEvery(actions.SET_KYC_STATUS, setKycStatus),
    takeEvery(actions.DELETE_KYC, deleteKYC),
    takeEvery(actions.FETCH_TOTAL_DATA, fetchTotalData),
    takeEvery(actions.USER_REGISTER, registerUser),
    takeEvery(actions.FORGOT_PASSWORD, forgotPassword),
    takeEvery(actions.RESET_PASSWORD, resetPassword),
    takeEvery(actions.FETCH_KYC, fetchKyc),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
