import store from 'store';
import apiClient from './index';

export async function login(email, password) {
  return apiClient
    .post('/auth/login', {
      email,
      password,
      actionType: 'login',
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function tfaLogin(payload) {
  const { token, code } = payload;

  return apiClient
    .post('/auth/login/tfa', { code }, {
      headers: {
        Authorization: `Bearer ${token}`,
        AccessToken: token
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  store.remove('accessToken')
}

export async function currentAccount() {
  return apiClient
    .get('/account/current')
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updatePassword(payload) {
  return apiClient
    .post('/account/password', payload)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function fetch2FA() {
  return apiClient
    .get('/account/2fa')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function disable2FA(payload) {
  return apiClient
    .post('/account/2fa/disable', {
      actionType: 'Disable 2FA',
      ...payload
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function enable2FA(payload) {
  return apiClient
    .post('/account/2fa/enable', {
      actionType: 'Enable 2FA',
      ...payload
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function fetchActionHistories() {
  return apiClient
    .get('/account/histories')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setAdminPassword(payload) {
  return apiClient
    .post(`auth/admin/password`, payload)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function forgotPassword(payload) {
  return apiClient
    .post(`auth/forgot-password`, payload)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function resetPassword(payload) {
  return apiClient
    .post(`auth/reset-password`, payload)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}
