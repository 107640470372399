import { all, takeEvery, put, call } from 'redux-saga/effects'

import * as CategoriesApi from '../../services/api/categories.api';

import actions from './actions'

export function* fetchCategories({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(CategoriesApi.fetchCategories, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        categories: response.data,
        totalCount: response.totalCount,
        loading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* fetchCategoryChats({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      categoryChatsLoading: true,
    },
  })

  const response = yield call(CategoriesApi.fetchCategoryChats, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        categoryChats: response,
        categoryChatsLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        categoryChatsLoading: false,
      },
    })
  }
}

export function* retrieveCategory({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      retrieveLoading: true,
    },
  })

  const response = yield call(CategoriesApi.retrieveCategory, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        category: response,
        retrieveLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        retrieveLoading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CATEGORIES, fetchCategories),
    takeEvery(actions.FETCH_CATEGORY_CHATS, fetchCategoryChats),
    takeEvery(actions.RETRIEVE_CATEGORY, retrieveCategory)
  ])
}
