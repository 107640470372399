import React from 'react';
import { connect } from 'react-redux'

import styles from './style.module.scss';

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  menuColor: settings.menuColor,
  theme: settings.theme,
  role: user.role,
})

const ThemeSwitch = ({
  theme,
  dispatch
}) => {
  const setTheme = nextTheme => {
    dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme: nextTheme,
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuColor',
        value: nextTheme === 'dark' ? 'dark' : 'light',
      },
    })
  }

  const handleClick = () => theme === 'default' ? setTheme('dark') : setTheme('default')

  return (
    <button className={styles.themeSwitch} onClick={handleClick} type='button'>
      <div className={styles.circle} />
      <img src="resources/images/Moon.png" className={styles.moon} alt="Moon" />
      <img src="resources/images/Sun.png" className={styles.sun} alt="Sun" />
    </button>
  )
}

export default connect(mapStateToProps)(ThemeSwitch);
