import apiClient from './index'

const baseUrl = '/comments';

export async function fetchComments(payload) {
  let limit = 10;
  let offset = 0;
  let author;
  let search;

  if (payload) {
    const { page, pageSize, author: user, search: searchTerm } = payload;

    if (pageSize) {
      limit = parseInt(pageSize, 10)
    }

    if (page && pageSize) {
      offset = (parseInt(page, 10) - 1) * (limit);
    }

    if (user) author = user;
    if (searchTerm) search = searchTerm;
  }

  return apiClient
    .get(baseUrl, {
      params: { limit, offset, author, search }
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function retrieveComment(id) {
  return apiClient
    .get(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function createComment(data) {
  const { text, postId, image } = data;

  const formData = new FormData();

  formData.append('text', text);
  formData.append('postId', postId);
  formData.append('image', image);

  return apiClient
    .post(baseUrl, formData, {
      'content-type': 'multipart/form-data'
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function updateComment(id, data) {
  const { text, postId, image } = data;

  const formData = new FormData();

  formData.append('text', text);
  formData.append('postId', postId);
  formData.append('image', image);

  return apiClient
    .put(`${baseUrl}/${id}`, formData, {
      'content-type': 'multipart/form-data'
    })
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function deleteComment(id) {
  return apiClient
    .delete(`${baseUrl}/${id}`)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}
