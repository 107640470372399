const actions = {
  SET_STATE: 'cities/SET_STATE',
  FETCH_CITIES: 'FETCH_CITIES',
  RETRIEVE_CITY: 'RETRIEVE_CITY',
  CREATE_CITY: 'CREATE_CITY',
  UPDATE_CITY: 'UPDATE_CITY',
  DELETE_CITY: 'DELETE_CITY',
}

export default actions
