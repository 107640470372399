import actions from './actions'

const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  avatar: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  users: [],
  fetchUsersLoading: false,
  analytics: {},
  totalData: {},
  fetchAnalyticsLoading: false,
  user: {},
  retrieveUserLoading: false,
  createUserLoading: false,
  updateUserLoading: false,
  deleteUserLoading: false,
  permissions: []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
