import React from 'react';
import { connect } from 'react-redux';
import Logout from '../../system/Auth/Logout';
import ThemeSwitch from '../../common/ThemeSwitch';
import styles from './style.module.scss';

const mapStateToProps = ({ user, settings }) => ({
  firstName: user.firstName,
  lastName: user.lastName,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView
})

const Header = ({ firstName, lastName, isMenuCollapsed, isMobileView }) => {
  const calculateLeft = () => {
    if (isMobileView) return 0;

    return isMenuCollapsed ? 80 : 270
  }

  return (
    <div
      className={styles.header}
      style={{
        left: calculateLeft()
      }}
    >
      <span className="font-size-18 mr-3">{`${firstName} ${lastName}`}</span>
      <Logout />
      <ThemeSwitch />
    </div>
  );
}

export default connect(mapStateToProps)(Header);
