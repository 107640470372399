const actions = {
  SET_STATE: 'accounts/SET_STATE',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  FETCH_2FA: 'FETCH_2FA',
  DISABLE_2FA: 'DISABLE_2FA',
  ENABLE_2FA: 'ENABLE_2FA',
  FETCH_ACTION_HISTORIES: 'FETCH_ACTION_HISTORIES',
  FETCH_ACCOUNTS: 'FETCH_ACCOUNTS',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  RETRIEVE_ACCOUNT: 'RETRIEVE_ACCOUNT',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  SET_ADMIN_PASSWORD: 'SET_ADMIN_PASSWORD',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  SET_API_KEY: 'SET_API_KEY',
  FETCH_API_KEY: 'FETCH_API_KEY'
}

export default actions
