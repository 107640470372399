import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'

import * as PostApi from '../../services/api/posts.api';

import actions from './actions'
import * as CommentsApi from '../../services/api/comments.api'

export function* fetchPosts({ payload })  {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(PostApi.fetchPosts, { ...payload, type: 'Post' })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        posts: response.data,
        totalCount: response.totalCount,
        loading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* fetchAds({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adsLoading: true,
    },
  })

  const response = yield call(PostApi.fetchPosts, { ...payload, type: 'AD' })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        ads: response.data,
        adsCount: response.totalCount,
        adsLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        adsLoading: false,
      },
    })
  }
}

export function* createPost({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      createLoading: true,
    },
  })

  const response = yield call(PostApi.createPost, payload)

  if (response) {
    yield put({
      type: actions.FETCH_POSTS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push(`/posts/${response.id}`)
    notification.success({
      message: 'Create Success',
      description: 'You have successfully create a post!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        createLoading: false,
      },
    })
  }
}

export function* retrievePost({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      retrieveLoading: true,
    },
  })

  const response = yield call(PostApi.retrievePost, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        post: response,
        retrieveLoading: false
      },
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        retrieveLoading: false,
      },
    })
  }
}

export function* updatePost({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updateLoading: true,
    },
  })

  const { id, body } = payload;

  const response = yield call(PostApi.updatePost, id, body)

  if (response) {
    if (response.type === 'AD') {
      yield put({
        type: actions.FETCH_ADS,
        payload: {
          page: 1,
          pageSize: 10
        }
      })
      yield history.push(`/ads/${id}`)
      notification.success({
        message: 'Update Success',
        description: 'You have successfully update a Ad!',
      })
    } else {
      yield put({
        type: actions.FETCH_POSTS,
        payload: {
          page: 1,
          pageSize: 10
        }
      })
      yield history.push(`/posts/${id}`)
      notification.success({
        message: 'Update Success',
        description: 'You have successfully update a post!',
      })
    }
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updateLoading: false,
      },
    })
  }
}

export function* deletePost({ payload }) {
  const { id } = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      deleteLoading: true,
    },
  })

  const response = yield call(PostApi.deletePost, id)

  if (response) {
    yield put({
      type: actions.FETCH_POSTS,
      payload: {
        page: 1,
        pageSize: 10
      }
    })
    yield history.push('/posts')
    notification.success({
      message: 'Delete Success',
      description: 'You have successfully delete a post!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        deleteLoading: false,
      },
    })
  }
}

export function* postComment({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      postCommentLoading: true,
    },
  })

  const response = yield call(CommentsApi.createComment, payload)

  if (response) {
    yield put({
      type: actions.RETRIEVE_POST,
      payload: {
        id: payload.postId
      }
    })

    yield history.push(`/posts/${payload.postId}`)

    notification.success({
      message: 'Create Success',
      description: 'You have successfully create a comment!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        postCommentLoading: false,
      },
    })
  }
}

export function* switchPost({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      switchAdLoading: true,
    },
  })

  const response = yield call(PostApi.switchPost, payload.id, payload.type)

  if (response) {
    yield put({
      type: actions.RETRIEVE_POST,
      payload: {
        id: payload.id
      }
    })

    if (response.type === 'AD') {
      yield history.push(`/ads/${response.id}`)
    } else {
      yield history.push(`/posts/${response.id}`)
    }

    notification.success({
      message: 'Switch post Success',
      description: 'You have successfully switch a post!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        switchAdLoading: false,
      },
    })
  }
}

export function* hidePost({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      hideLoading: true,
    },
  })

  const response = yield call(PostApi.hidePost, payload.id)

  if (response) {
    yield put({
      type: actions.RETRIEVE_POST,
      payload: {
        id: payload.id
      }
    })

    yield history.push(`/posts/${payload.id}`)

    notification.success({
      message: 'Hide post Success',
      description: 'You have successfully hidden a post!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        hideLoading: false,
      },
    })
  }
}

export function* showPost({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      showLoading: true,
    },
  })

  const response = yield call(PostApi.showPost, payload.id)

  if (response) {
    yield put({
      type: actions.RETRIEVE_POST,
      payload: {
        id: payload.id
      }
    })

    yield history.push(`/posts/${payload.id}`)

    notification.success({
      message: 'Show post Success',
      description: 'You have successfully shown a post!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        showLoading: false,
      },
    })
  }
}

export function* promotedPost({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      promotedPostLoading: true,
    },
  })

  const response = yield call(PostApi.promotedPost, payload.id, payload.position)

  if (response) {
    yield put({
      type: actions.RETRIEVE_POST,
      payload: {
        id: payload.id
      }
    })

    if (response.type === 'AD') {
      yield history.push(`/ads/${response.id}`)
    } else {
      yield history.push(`/posts/${response.id}`)
    }

    notification.success({
      message: 'Set Promoted Position Success',
      description: 'You have successfully set promoted position!',
    })
  }
  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        promotedPostLoading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_POSTS, fetchPosts),
    takeEvery(actions.CREATE_POST, createPost),
    takeEvery(actions.RETRIEVE_POST, retrievePost),
    takeEvery(actions.UPDATE_POST, updatePost),
    takeEvery(actions.DELETE_POST, deletePost),
    takeEvery(actions.FETCH_ADS, fetchAds),
    takeEvery(actions.POST_COMMENT, postComment),
    takeEvery(actions.SWITCH_POST, switchPost),
    takeEvery(actions.HIDE_POST, hidePost),
    takeEvery(actions.SHOW_POST, showPost),
    takeEvery(actions.SET_PROMOTED_POST, promotedPost)
  ])
}
