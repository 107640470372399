import axios from 'axios'
import apiClient from './index'
import env from '../../env'

const userApiClient = axios.create({
  baseURL: env.API_USER_URL || 'http://localhost:1337/api/v2',
})


export async function register(data) {
  return apiClient
    .post('/auth/user-register', data)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function postConfirmation(data) {
  return apiClient
    .post('/auth/confirmation', data)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => console.log(err));
}

export async function login(data) {
  return userApiClient
    .post('/auth/login', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false;
    })
    .catch(err => {
      console.log(err);
      return Promise.reject(err);
    });
}

export async function postMobileConfirmation(data) {
  return userApiClient
    .post('/auth/confirmation', data)
    .then(response => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(err => Promise.reject(err));
}
